import React from 'react'

class Footer extends React.Component {
    
  render() {
    return (
        <div className="Footer">
            <footer>
                <p>&copy; 2020 Utterly Incongruous Records | <a href="mailto:info@utterlyincongruous.net">info@utterlyincongruous.net</a></p>
                <p><a href=""><img src="./images/fbblue.png" /></a></p>
            </footer>
        </div>
    )
  }
}
    
export default Footer
import $ from 'jquery'

export const addOrder = order => {
    const urlString = "http://shop.utterlyincongruous.net:9000/orders"
    return $.ajax({
        url: urlString,
        method: "POST",
        data: order,
        success: () => {
            console.log('order logged')
        },
        error: (status, err) => {
            console.log(status)
        }
    })
  }
import React from 'react'
import Footer from '../components/Footer'
class FooterContainer extends React.Component {
   
  render() {
    return (
            <Footer />
        )
  }
}
    
export default FooterContainer
import React from 'react'
import CartContainer from './CartContainer'
import Products from '../components/Products'
import FooterContainer from './FooterContainer'
import CheckoutContainer from './CheckoutContainer'
import CookieMessage from './CookieMessage'
import Feedback from './Feedback'
import { CSSTransitionGroup } from 'react-transition-group'

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            type: 'all',
            loading: true,
            showFeedback: false
        }
        this.showHideFeedback = this.showHideFeedback.bind(this)
    }
    getAll(event) {
        this.setState({type: 'all'})
        event.preventDefault()
    }
    getAlbums(event) {
        this.setState({type: 'cd'})
        event.preventDefault()
    }
    getClothing(event) {
        this.setState({type: 'tshirts'})
        event.preventDefault()
    }
    getPosters(event) {
        this.setState({type: 'posters'})
        event.preventDefault()
    }
    componentDidMount() {
        this.setState({loading: false})
    }
    showHideFeedback() {
        this.setState({
            showFeedback: !this.state.showFeedback
        })
    }
  render() {
      const { loading } = this.state
      if (loading) {
          return (
              <div className="loading">
                <img src="./images/loading.gif" />      
            </div>
        )
      } else {
          return (
          <div>
            <header>
                <img src="images/beta.png" className="beta" />
                <img src="images/UIR_logo.png" alt="Utterly Incongruous Records" />
                <div className="Cart">
                    <CartContainer />
                </div>
            </header>
            <div className="navigation">
                <nav>
                    <ul>
                        <li><a href="https://utterlyincongruous.net">Home</a></li>
                        <li className={this.state.type === 'all' ? 'active' : ''}><a href="#" onClick={this.getAll.bind(this)}>All Products</a></li>
                        <li className={this.state.type === 'cd' ? 'active' : ''}><a href="#" onClick={this.getAlbums.bind(this)}>Albums</a></li>
                        <li className={this.state.type === 'tshirts' ? 'active' : ''}><a href="#" onClick={this.getClothing.bind(this)}>Clothing</a></li>
                        <li className={this.state.type === 'posters' ? 'active' : ''}><a href="#" onClick={this.getPosters.bind(this)}>Posters</a></li>

                    </ul>
                    <a className="feedback-button" onClick={this.showHideFeedback}><img src="./images/feedback.svg" width="25" /></a>
                </nav>
            </div>
            <div className="content">
                <CSSTransitionGroup
                  transitionName="groupappear"
                  transitionAppear={true}
                  transitionAppearTimeout={500}
                  transitionEnter={false}
                  transitionLeave={false}>
                    <Products type={this.state.type} />
                </CSSTransitionGroup>
            </div>
            <FooterContainer />
            <CheckoutContainer />
            <CookieMessage />
            <Feedback show={this.state.showFeedback} closeHandler={this.showHideFeedback} />
          </div>
        )
      }
    
  }
}
export default App

import React from 'react';
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'

class Product extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }
    constructor(props) {
        super(props)
         const { cookies } = props
        this.state = {
            showDetails: false,
            showModal: false,
            cart: [],
            buttonState: 'active',
            prods: cookies.get('uircartprods') || '',
            buttonText: 'Add to cart',
            quantity: 1
        }
        this.imgPath = 'https://www.utterlyincongruous.net/catalog/products/'
    }
    componentDidMount() {
        const { cookies } = this.props
        var ccookie = cookies.get('uircartprods') || ''
        let carray = ccookie.split(',').filter(function(el) {
            return el !== ''
        })
        let isContained = false
        for (var i = 0; i < carray.length; i++) {
            if (carray[i] === this.props.product.uid.toString()) {
                isContained = true
            }
        }
        if (isContained) {
            this.setState({buttonState: 'disabled', buttonText: 'Added to cart'})
        }
    }
    handleClick() {
        this.setState({buttonState: 'disabled', buttonText: 'Added to cart'})
    }
    render() {
       
    return (
        <div className="Product">
            <section>
                <img src={this.imgPath + this.props.product.image} />
            </section>
            <section>
                <div>
                    <span className="remove" onClick={this.props.removeHandler}>×</span>
                    <h2>{this.props.product.product_name}</h2>
                    <p className="category">{this.props.product.category}</p>
                    <p>{this.props.product.description}</p>
                    <h4>&#36;{this.props.product.price}</h4>
                    <button className="button"
                        id={this.props.product.uid} 
                        onClick={() => { this.props.handler()
                                       this.handleClick() }}
                        disabled={this.state.buttonState === 'active' ? false : true}
                    >{this.state.buttonText}</button>
                   
                </div>
            </section>
        </div>
        )
    }
}

export default withCookies(Product)
import React from 'react'
import { PayPalButton } from 'react-paypal-button-v2'
import { sendCheckout } from '../actions/sendcheckout'

class PayButton extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            paid: false,
            show: props.show,
            error: false
        }
       
    }
    componentDidUpdate(prevProps) {
      if (this.props.show !== prevProps.show) {
        this.setState({show: this.props.show})
      }
    }
    render() {
        const { show } = this.state
        const iserror = false
        const isGood = false
        if (show) {
            return (
                <PayPalButton
                    amount={this.props.total.toString()}
                    shippingPreference="NO_SHIPPING"
                    onSuccess={(details, data) => {
                      console.log("Transaction completed by " + details.payer.name.given_name)
                        sendCheckout(data.orderID)
                        show = false
                        iserror = false
                        isGood = true
                    }}
                    onError={(err) => {
                        iserror = true
                    }}
                    options={{
                      clientId: "AQI_rlh2MyQJ52HAZw8h2hieUYqfiRsKRSWgEpT7BiPmKPL07na5FxQrBB2vB2o4peApcmCcK47ejFn4"
                    }}
                  />
            )
        } else {
            if (iserror) {
                return (
                    <div className="message">
                        <img src="/images/error.png" />
                        <p>An error has occurred with your purchase. Please contact the site admin at <a href="mailto:jonathan@utterlyincongruous.net">jonathan@utterlyincongruous.net</a>.</p>
                    </div>
                )
            } else if (isGood) {
                return (
                    <div className="message">
                        <img src="/images/success.png" />
                        <p>Your payment has been processed. Thanks buddy!</p>
                    </div>
                )
            } else {
                return (
                    <div>
                        <p>Provide your shipping details to continue with your purchase.</p>
                    </div>
                )
            }
        }
    }
}

export default PayButton
import React from 'react'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'

class CookieMessage extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }
    constructor(props) {
        super(props)
        const { cookies } = props
        this.state = {
            prods: cookies.get('uirpageref') || '',
            show: true,
        }
        
        this.handleClose = this.handleClose.bind(this)
        this.handleLeave = this.handleLeave.bind(this)
    }
    componentDidMount() {
        if (this.state.prods !== '') {
            this.setState({show: false})
        }
    }
    handleClose() {
        this.setState({show: !this.state.show})
        const { cookies } = this.props
        var now = new Date()
        now.setFullYear(now.getFullYear() + 1)
        cookies.set('uirpageref', 'aux234', { path: '/', expires: now, sameSite: true})
    }
    handleLeave() {
        const { cookies } = this.props
        var now = new Date()
        now.setFullYear(now.getFullYear() + 1)
        cookies.set('uirpageref', 'aux234', { path: '/', expires: now, sameSite: true})
        window.location.href = 'https://www.utterlyincongruous.net'
        
    }
    render() {
        if (this.state.show) {
            return (
                <div className="cookie-message">
                    <span className="closeBtn" onClick={this.handleClose}>×</span>
                    <div>
                        <img src="./images/cookies.svg" width="130" />
                    </div>
                    <div>
                        <h5>Cookies</h5>
                        <p>This site uses cookies to keep track of your orders. No personal information or tracking is kept. If you have disabled cookies, some features will not work.</p>
                        <button onClick={this.handleClose}>OK</button> <button onClick={this.handleLeave}>Leave</button>
                    </div>
                </div>
            )
        } else {
            return (null)
        }
    }
}

export default withCookies(CookieMessage)
import React from 'react'
import { getProductsById } from '../api/get'
import Product from './Product'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import { triggerClick } from '../actions/triggerclose'
import { CSSTransitionGroup } from 'react-transition-group'

class Cart extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }
    constructor(props) {
        super(props)
         const { cookies } = props
            this.state = {
            prods: cookies.get('uircartprods') || '',
            collapsed: true,
            checkout: false,
            total: 0,
            products: props.products
        }
        this.expand = this.expand.bind(this)
        this.p = []
    }
    componentDidMount() {
        this.getCartProducts(this.state.prods)
    }

    componentDidUpdate(prevProps) {
        const { cookies } = this.props
        var ccookie = cookies.get('uircartprods') || ''
        if (ccookie !== this.state.prods) {
            this.setState({prods: ccookie})
            this.getCartProducts(ccookie)
        }
    }
    getCartProducts = async (c) => {
        let products = []
        let tot = 0
        var prodPrices = []
        if (c !== '') {
            let ts = c.split(',').filter(function(el) {
                return el !== ''
            })
            for (var x = 0; x < ts.length; x++) {
                var p = await getProductsById(ts[x])
                var importedProducts = JSON.parse(p)
                let k = 0
                
                importedProducts.map((product) => {    
                    const prod = <Product key={product.uid} product={product} removeHandler={() => this.removeItem(k)} />
                    products.push(prod)
                    prodPrices.push(product.price)
                    k++
                })
                
            }
            tot = prodPrices.reduce(function(a, b) {
                return a + b
            }, 0)
            this.setState({products: products})
            this.setState({total: tot})
        } else {
            this.setState({products: []})
            this.setState({total: 0})
        }
        
    }
    expand(event) {
        this.setState({collapsed: !this.state.collapsed})
    }
    handleCheckout() {
        triggerClick()
    }
    
    removeItem(id) {
        const { cookies } = this.props
        var now = new Date()
        now.setMonth(now.getMonth() + 1)
        let cv = this.state.prods
        let productArray = cv.split(',').filter(function(el) {
            return el !== ''
        })
        let newArray = productArray.splice(id, 1)
        let adj = newArray.length > 0 ? newArray.toString() : ''
        cookies.set('uircartprods', adj, { path: '/', sameSite: true})
        this.setState({prods: adj})
        this.getCartProducts(adj)
    }
  render() {
    return (
            <div className={this.state.collapsed ? 'collapsed' : ''}>
                <h6>Your cart</h6>
                <i className="basket-icon"><img src="/images/icon-basket.svg" /></i>
                <p className={this.state.prods ? 'hidden' : ''}>Your cart is empty</p>
                <CSSTransitionGroup
                    transitionName="example"
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={500}>
                    {this.state.products}
                </CSSTransitionGroup>
                <h6>Total: &#36;{this.state.total}</h6>
                <button onClick={this.handleCheckout} className={this.state.prods ? '' : 'hidden' }>Checkout</button>
                <img src="./images/paypal.png" className="paypal" alt="Paypal" />
                <a className="minify" onClick={this.expand} alt="My cart"></a>
                <span className={this.state.prods ? 'pulse' : 'hidden'}>{this.state.products.length}</span>
            </div>
        )
  }
}
    
export default withCookies(Cart)
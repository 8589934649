import React from 'react'
import Checkout from '../components/Checkout'
import { CSSTransitionGroup } from 'react-transition-group'

class CheckoutContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            products: [],
            total: 0.00,
            showCheckout: false,
        }
        this.handleShow = this.handleShow.bind(this)
    }
    handleShow() {
        this.setState({showCheckout: !this.state.showCheckout})
    }
  render() {
    return (
            <div className={this.state.showCheckout ? '' : 'hidden' }>
                <CSSTransitionGroup
                    transitionName="example"
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={500}>
                    <Checkout showHandler={this.handleShow} />
                </CSSTransitionGroup>
            </div>
        )
  }
}
    
export default CheckoutContainer
import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { CSSTransitionGroup } from 'react-transition-group'
import emailjs from 'emailjs-com'

class Feedback extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            isValid: false,
            completed: false
        }
    }
    onchange = function(value) {
        console.log("Captcha value: " + value)
        if (value !== '') {
            this.setState({isValid: true})
        }
    }
    handleSubmit = async (event) => {
        if (this.state.isValid) {
            event.preventDefault()
           emailjs.sendForm('service_xpd1ram', 'template_gptlwwh', event.target, 'user_l1woc0RAr2xtdW9Py2Edp')
           .then((result) => {
               this.setState({completed: true})
           }, (error) => {
               console.log(error.text)
           })
        }
    }
    render() {
        if (this.props.show) {
            return (
                <div className="overlay">
                    <CSSTransitionGroup
                      transitionName="groupappear"
                      transitionAppear={true}
                      transitionAppearTimeout={500}
                      transitionEnter={false}
                      transitionLeave={false}>
                        <div className="window">
                            <span className="close-large" id="Cclose" onClick={this.props.closeHandler}>×</span>
                            <h2>Send us your feedback</h2>
                            <form onSubmit={this.handleSubmit} className={this.state.completed ? 'hidden' : ''}>
                                <label>Name:</label>
                                <input type="text" name="Name" required />
                                <label>Email address</label>
                                <input type="email" name="Email" required />
                                <label>Your feedback</label>
                                <textarea name="Feedback" className="large" required />
                                <ReCAPTCHA
                                    sitekey="6LdeitEUAAAAABXwJ5kRlG-syWH_YS4zvGB-vNEo"
                                    onChange={this.onchange}
                                  />
                                <input type="submit" value="submit" disabled={!this.state.isValid} />
                            </form>
                            <div className={this.state.completed ? '' : 'hidden'}>
                                <img src="./images/success.png" alt="Sent" width="200" />
                                <p>Thanks for the feedback.</p>
                            </div>
                        </div>
                    </CSSTransitionGroup>
                </div>
            )
        } else {
            return (null)
        }
    }
}

export default Feedback
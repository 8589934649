import React from 'react'
import { getProductsById } from '../api/get'
import { addOrder } from '../api/post'
import Product from './Product'
import PayButton from './PayButton'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import { getCountries } from '../actions/getcountries'
import emailjs from 'emailjs-com'
import { CSSTransitionGroup } from 'react-transition-group'

class Checkout extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }
    constructor(props) {
        super(props)
        const { cookies } = props
        const d = new Date()
        const date = d.toISOString().slice(0, 19).replace('T', ' ')
        this.state = {
            prods: cookies.get('uircartprods') || '',
            total: 0,
            products: [],
            postage: 3,
            showForm: true,
            order: {
                oid: '',
                order_date: date,
                products: '',
                first_name: '',
                last_name: '',
                email: '',
                address: '',
                city: '',
                province: '',
                zip: '',
                country: '',
                order_status: 'new',
                order_details: ''
            }
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handlePostage = this.handlePostage.bind(this)
        this.countries = getCountries()
        this.handleChange = this.handleChange.bind(this)
        this.getCartProducts(this.state.prods)
    }
    componentDidUpdate(prevprops) {
        const { cookies } = this.props
        const c = cookies.get('uircartprods') || ''
        if (c !== this.state.prods) {
            this.setState({prods: c})
            this.getCartProducts(c)
        }
    }
   
    getCartProducts = async (c) => {
        let products = []
        let tot = 0
        let titles = []
        if (c !== '') {
            const ts = c.split(',').filter(function(el) {
                return el !== ''
            })
            for (var x = 0; x < ts.length; x++) {
                var p = await getProductsById(ts[x])
                var importedProducts = JSON.parse(p)
                importedProducts.map((product, i) => {
                    const prod = <Product key={product.uid} product={product} removeHandler={() => this.removeItem(i)} />
                    products.push(prod)
                    tot += product.price
                })
            }
            this.setState({
                products: products,
                total: tot + this.state.postage
            })
        }
        products.map((product) => {
            titles.push(product.title)
        })
        this.setState({
            order: {
                products: titles
            }
        })
    }
   handleSubmit = async (event) => {
       const { cookies } = this.props
       event.preventDefault()
       emailjs.sendForm('service_xpd1ram', 'template_g7kx297', event.target, 'user_l1woc0RAr2xtdW9Py2Edp')
       .then((result) => {
           console.log('Form Sent')
       }, (error) => {
           console.log(error.text)
       })
       var r = await addOrder(this.state.order)
       const t = await r
       cookies.set('uircartprods', '', { path: '/', sameSite: true})
       this.setState({showForm: false})
       this.setState({prods: ''})
       this.setState({products: []})
       this.setState({total: 0})
   }
    handlePostage(event) {
        if (event.target.value === 'South Africa') {
            this.setState({postage: 3})
        } else {
            this.setState({postage: 5})
        }
        this.setState({total: this.state.total + this.state.postage})
    }
    handleChange(event) {
        const value = event.target.value
        this.setState({
            order: {
            ...this.state.order,
            [event.target.name]: value
            }
          });
        const formElements = document.getElementById('checkoutForm').elements
        let varray = []
        for (var i = 0; i < formElements.length; i++) {
            if (formElements[i].value) {
                varray.push('v');
            }
        }
        let isValid = varray.length === formElements.length ? true : false
        this.setState({showButtons: isValid})
    }
    removeItem(id) {
        const { cookies } = this.props
        var now = new Date()
        now.setMonth(now.getMonth() + 1)
        let cv = this.state.prods
        let productArray = cv.split(',').filter(function(el) {
            return el !== ''
        })
        let newArray = productArray.splice(id, 1)
        let adj = newArray.length > 0 ? newArray.toString() : ''
        cookies.set('uircartprods', adj, { path: '/', expires: now, sameSite: true})
        this.setState({prods: adj})
        this.getCartProducts(adj)
    }
  render() {
      let prodArray = []
      const p = this.state.products
      console.log(p)
     
    return (
        <div className="overlay">
            <div className="Checkout">
                <span className="close-large" id="Cclose" onClick={this.props.showHandler}>×</span>
                <section className={this.state.showForm ? '' : 'hidden'}>
                    <h2>Shipping details</h2>
                    <p>Enter your delivery details. Delivery time four to six weeks</p>
                    <form id="checkoutForm" onSubmit={this.handleSubmit}>
                        <input type="hidden" id="OID" name="oid"  />
                        <input type="hidden" name="order_date" value={this.state.order.order_date} />
                        <input type="hidden" name="products" value={this.state.order.products} />
                        <label>Name</label>
                        <input type="text" name="first_name" value={this.state.order.first_name} onChange={this.handleChange} required alt="Please enter your name" />
                        <label>Surname</label>
                        <input type="text" name="last_name" value={this.state.order.last_name} onChange={this.handleChange} required alt="Please enter your surname" />
                        <label>Email address</label>
                        <input type="email" name="email" required value={this.state.order.email} onChange={this.handleChange} alt="Please enter an email" />
                        <label>Address</label>
                        <input type="text" required name="address" value={this.state.order.address} onChange={this.handleChange} alt="Please enter an address" />
                        <label>City/suburb</label>
                        <input type="text" required name="city" value={this.state.order.city} onChange={this.handleChange} />
                        <label>State/Province</label>
                        <input type="text" required name="province" value={this.state.order.province} onChange={this.handleChange} />
                        <label>Post/zip code</label>
                        <input type="text" required name="zip" className="short" value={this.state.order.zip} onChange={this.handleChange} />
                        <label>Country</label>
                        <select onChange={this.handleChange} required name="country" value={this.state.order.country}>
                            { this.countries.map((country, i) => {
                                return <option value={country} key={i}>{country}</option>
                            })}
                        </select>
                        <label>Order notes</label>
                        <textarea name="order_details" value={this.state.order.description} onChange={this.handleChange} />
                    </form>
                </section>
                <section className={this.state.showForm ? 'hidden' : ''}>
                    <p>Your order has been processed</p>    
                </section>
                <section>
                    <h2>Your cart</h2>
                    <p className={this.state.products.length ? 'hidden' : ''}>Your cart is empty</p>
                    <CSSTransitionGroup
                        transitionName="example"
                        transitionEnterTimeout={500}
                        transitionLeaveTimeout={500}>
                        {this.state.products}
                    </CSSTransitionGroup>
                    <br />
                    <p>Shipping cost: &#36;{this.state.postage}</p>
                    <h5>Total: &#36;{this.state.total}</h5>
                    <div className={this.state.products.length ? '' : 'hidden'}>
                        <PayButton total={this.state.total} show={this.state.showButtons} />
                    </div>
                </section>
            </div>
        </div>
    )
  }
}
    
export default withCookies(Checkout)

import React from 'react'
import Cart from '../components/Cart'
class CartContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            products: [],
            total: 0.00
        }
    }
    
  render() {
    return (
            <Cart
                products={this.state.products}
            />
        )
  }
}
    
export default CartContainer
import $ from 'jquery'

export const getAll = () => {
    const urlString = "http://shop.utterlyincongruous.net:9000/products"
    return $.ajax({
        url: urlString,
        type: 'GET',
        crossDomain: true,
        success: (latestResults) => {
            const results = JSON.parse(latestResults)
            return results
        },
        error: (xhr, status, err) => {
            console.error("Failed to fetch data")
          }
    })
}

export const getAllAlbums = () => {
    const urlString = "http://shop.utterlyincongruous.net:9000/products/cd"
    return $.ajax({
        url: urlString,
        success: (latestResults) => {
            const results = JSON.parse(latestResults)
            return results
        },
        error: (xhr, status, err) => {
            console.error("Failed to fetch data")
          }
    })
}

export const getAllClothing = () => {
    const urlString = "http://shop.utterlyincongruous.net:9000/products/tshirts"
    return $.ajax({
        url: urlString,
        success: (latestResults) => {
            const results = JSON.parse(latestResults)
            return results
        },
        error: (xhr, status, err) => {
            console.error("Failed to fetch data")
          }
    })
}

export const getAllPosters = () => {
    const urlString = "http://shop.utterlyincongruous.net:9000/products/posters"
    return $.ajax({
        url: urlString,
        success: (latestResults) => {
            const results = JSON.parse(latestResults)
            return results
        },
        error: (xhr, status, err) => {
            console.error("Failed to fetch data")
          }
    })
}

export const getProductsById = id => {
    const urlString = "http://shop.utterlyincongruous.net:9000/product/" + id
    return $.ajax({
        url: urlString,
        success: (latestResults) => {
            const results = latestResults
            return results
        },
        error: (xhr, status, err) => {
            console.error("Failed to fetch data")
          }
    })
}
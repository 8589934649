import React from 'react'
import Product from './Product'
import { getAll, getAllAlbums, getAllClothing, getAllPosters } from '../api/get'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import { CSSTransitionGroup } from 'react-transition-group'

class Products extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }
    constructor(props) {
        super(props)  
        const { cookies } = props
        this.state = {
             prods: cookies.get('uircartprods') || '',
            buttonState: 'active',
            products: []
        }
        this.p = []
        this.getAllProducts()
    }
    handleClick(o) {
        const { cookies } = this.props
        var now = new Date()
        now.setMonth(now.getMonth() + 1)
        var g = cookies.get('uircartprods') || ''
        var t = g.split(',').filter(function(el) {
            return el != ''
        })
        t.push(o.uid)
        this.setState({prods: t.toString()})
        cookies.set('uircartprods', t.toString(), { path: '/', expires: now, sameSite: true})
    }
    getAllProducts = async () => {
        var p = await getAll()
        var importedProducts = JSON.parse(p)
        let products = []
        importedProducts.forEach((product) => {
            const prod = <Product key={product.uid} product={product} handler={() => this.handleClick(product)} />
              products.push(prod)
        })
        this.setState({products: products})
    }
    getAllAlbums = async () => {
        var p = await getAllAlbums()
        var importedProducts = JSON.parse(p)
        let products = []
        if (importedProducts.length > 0) {
            importedProducts.forEach((product) => {
                const prod = <Product key={product.uid} product={product} handler={() => this.handleClick(product)}/>
                  products.push(prod)
            })
        }
        this.setState({products: products})
    }
    getAllClothing = async () => {
        var p = await getAllClothing()
        var importedProducts = JSON.parse(p)
        let products = []
        importedProducts.forEach((product) => {
            const prod = <Product key={product.uid} product={product} handler={() => this.handleClick(product)}/>
              products.push(prod)
        })
        this.setState({products: products})
    }
    getAllPosters = async () => {
        var p = await getAllPosters()
        var importedProducts = JSON.parse(p)
        let products = []
        importedProducts.forEach((product) => {
            const prod = <Product key={product.uid} product={product} handler={() => this.handleClick(product)}/>
              products.push(prod)
        })
        this.setState({products: products})
    }
    componentDidUpdate(prev) {
        if (prev.type !== this.props.type) {
            switch (this.props.type) {
                case 'cd':
                    this.getAllAlbums()
                    break
                case 'tshirts':
                    this.getAllClothing()
                    break
                case 'posters':
                    this.getAllPosters()
                    break
                default:
                    this.getAllProducts()
                    break
            }
        }
    }
  render() {
      const hasProducts = this.state.products.length ? true : false
      if (hasProducts) {
          return (
            <div className="Products">
              <CSSTransitionGroup
                transitionName="example"
                transitionAppear={true}
                transitionAppearTimeout={500}
                transitionEnterTimeout={500}
                transitionLeaveTimeout={500}>
                    {this.state.products}
              </CSSTransitionGroup>
            </div>
        )
      } else {
          return (
            <div className="not-found">
                <img src="/images/error.png" />
                <h2>Nothing found</h2>
            </div>
        )
      }
    
  }
}
    
export default withCookies(Products)